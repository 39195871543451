import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Thanks } from '../../layout/Thanks';

import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';

import { AboutValuationText } from '../../partials/AboutValuationText';

import { ValuationForm } from '../../partials/forms/Valuation';
import { useValuationContext } from '../../../utils/hooks/useValuationContext';
import { ValuationContextProvider } from '../../../utils/context/ValuationContext';
import { PageType } from '../../../__types__/generated/globalTypes';

const Index: React.FC = () => {
  const { posted, init } = useValuationContext();

  React.useEffect(() => {
    init({
      pageType: PageType.GENERELL,
      showNameFields: true,
      showAddressField: false,
      showEmailField: false
    });
  }, []);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  return (
    <>
      <Seo
        title="La oss sette en av landets beste eiendomsmeglere på saken - PrivatMegleren"
        description="Som kunde hos PrivatMegleren får du en skreddersydd prosess tilpasset dine behov og ønsker."
      />
      <Container className="padding-top" style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              La oss sette en av landets beste eiendomsmeglere på saken
            </Heading>
            <Paragraph className="form-text">
              Vi har en klar strategi: å samle de beste meglerne og gi dem de
              beste verktøy for lykkes med ditt eiendomssalg. Skal du selge
              eiendommen din bør du ikke legge den ut for salg før du har
              mottatt et tilbud fra en av våre eiendomsmeglere. PrivatMegleren
              har resultater som ingen andre kan vise til, både når det gjelder
              salg av hus og leilighet.
            </Paragraph>
            <Paragraph className="form-text">
              Legg igjen navn, nummer og postnummer. Vi gir deg med glede et
              uforpliktende tilbud.
            </Paragraph>
          </Section>
          <ValuationForm />
          <Section className="form-section">
            <AboutValuationText />
          </Section>
          <Section className="form-section">
            <AccordionBenefits />
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.4}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

const ValuationSignatur: React.FC = (props) => {
  return (
    <ValuationContextProvider>
      <Index {...props} />
    </ValuationContextProvider>
  );
};

export default ValuationSignatur;

const FormWrapper = styled(animated.div)``;
